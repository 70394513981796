import React from "react";
import "./SearchBar.css";
import Loader from "../Loader/Loader.js";
import {Link} from 'react-router-dom';
// import onlySearch from './OnlySearchBar.js';
import allstates from "../CreatePost/state.js";
import Moment from "react-moment";
import "moment-timezone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Notifications, { notify } from "react-notify-toast";
import axios from "axios";

let customNotify = {
  background: "#000",
  text: "#fff",
};
class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      cityPosts: [],
      x: 0,
      y: 0,
      error: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onChange = (event) => {
    this.setState({ loaded: false, x: 0, error: 0 });
    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };
  Onpop = (event) => {
    notify.show("Number Copied", "custom", 500, customNotify);
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ x: 1 });
    localStorage.setItem("zipcode", this.state.search);

    axios
      .get(
        `https://covidnetwork-server.herokuapp.com/api/InNeedPost/city/${this.state.search}`,
        {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SECRET_TOKEN}`,
          },
        }
      )
      .then((res) => {
        this.setState({ cityPosts: res.data });
        if (res.data > 0) this.setState({ loaded: true });
        else {
          this.setState({ loaded: true });
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });

        this.setState({ error: 1 });
      });
  };

  render() {
    const x = this.state.cityPosts.map((cityPost, index) => {
      if (index % 2 === 0) {
        return (
          <div key={index} className="card-container container">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons post-heading">
                    {cityPost.requirement} NEEDED
                  </i>
                </div>
              </div>
              <div className="card-body">
                <h4 className="card-title">
                  <span className="sub-heading">Name:{"  "}</span>
                  {cityPost.name}
                  <span className="sub-heading">
                    {"  "}Age:{"  "}
                  </span>
                  {cityPost.age}
                </h4>
                <p className="card-text">
                  <span className="sub-heading">City:{"  "}</span>
                  {cityPost.city}
                </p>
                <p className="card-text">
                  <span className="sub-heading">Mobile Number:{"  "}</span>
                  {cityPost.mob1}
                  <CopyToClipboard text={cityPost.mob1}>
                    <button
                      className="copy-btn"
                      id="copyButton"
                      name="copyId"
                      value={index}
                      onClick={() => this.Onpop(index)}
                    >
                      <i className="far fa-copy"></i>
                    </button>
                  </CopyToClipboard>
                </p>

                <p className="card-text">
                  <span className="sub-heading">Details:{"  "}</span>
                  {cityPost.detail}
                </p>
                <p className="card-text">
                  <span className="sub-heading">Posted On:{"  "}</span>
                  <Moment format="DD/MM/YYYY">{cityPost.createdAt}</Moment>
                </p>

                {/* <a href="/" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        );
      } else {
        return <div key={index}></div>;
      }
    });
    const y = this.state.cityPosts.map((cityPost, index) => {
      if (index % 2 !== 0) {
        return (
          <div key={index} className="card-container container">
            <div className="card">
              <div className="card-header card-header-icon card-header-rose">
                <div className="card-icon">
                  <i className="material-icons post-heading">
                    {cityPost.requirement} NEEDED
                  </i>
                </div>
              </div>
              <div className="card-body">
                <h4 className="card-title">
                  <span className="sub-heading">Name:{"  "}</span>
                  {cityPost.name}
                  <span className="sub-heading">
                    {"  "}Age:{"  "}
                  </span>
                  {cityPost.age}
                </h4>
                <p className="card-text">
                  <span className="sub-heading">City:{"  "}</span>
                  {cityPost.city}
                </p>
                <p className="card-text">
                  <span className="sub-heading">Mobile Number:{"  "}</span>
                  {cityPost.mob1}
                  <CopyToClipboard text={cityPost.mob1}>
                    <button
                      className="copy-btn"
                      id="copyButton"
                      name="copyId"
                      value={index}
                      onClick={() => this.Onpop(index)}
                    >
                      <i className="far fa-copy"></i>
                    </button>
                  </CopyToClipboard>
                </p>

                <p className="card-text">
                  <span className="sub-heading">Details:{"  "}</span>
                  {cityPost.detail}
                </p>
                <p className="card-text">
                  <span className="sub-heading">Posted On:{"  "}</span>
                  <Moment format="DD/MM/YYYY">{cityPost.createdAt}</Moment>
                </p>

                {/* <a href="/" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        );
      } else {
        return <div key={index}></div>;
      }
    });
    const z = this.state.cityPosts.map((cityPost, index) => {
      return (
        <div key={index} className="card-container container">
          <div className="card">
            <div className="card-header card-header-icon card-header-rose">
              <div className="card-icon">
                <i className="material-icons post-heading">
                  {cityPost.requirement} NEEDED
                </i>
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">
                <span className="sub-heading">Name:{"  "}</span>
                {cityPost.name}
                <span className="sub-heading">
                  {"  "}Age:{"  "}
                </span>
                {cityPost.age}
              </h4>
              <p className="card-text">
                <span className="sub-heading">City:{"  "}</span>
                {cityPost.city}
              </p>
              <p className="card-text">
                <span className="sub-heading">Mobile Number:{"  "}</span>
                {cityPost.mob1}
                <CopyToClipboard text={cityPost.mob1}>
                  <button
                    className="copy-btn"
                    id="copyButton"
                    name="copyId"
                    value={index}
                    onClick={() => this.Onpop(index)}
                  >
                    <i className="far fa-copy"></i>
                  </button>
                </CopyToClipboard>
              </p>

              <p className="card-text">
                <span className="sub-heading">Details:{"  "}</span>
                {cityPost.detail}
              </p>
              <p className="card-text">
                <span className="sub-heading">Posted On:{"  "}</span>
                <Moment format="DD/MM/YYYY">{cityPost.createdAt}</Moment>
              </p>

              {/* <a href="/" className="btn btn-primary">Go somewhere</a> */}
            </div>
          </div>
        </div>
      );
    });

    if (this.state.x === 0 && this.state.y === 0) {
      return (
        <div className="container">
          <div className="container search-container">
            <div className=" in-post-inner post-opt-inner">
              <h3 className="av-alert btn-head">
                Search for post created by people in your city and be the voice
                someone is waiting to hear.
              </h3>
            </div>
            <form onSubmit={this.onFormSubmit}>
              <div className="row">
                <div className="col-lg-4 col-md-12 search-grp form-group">
                  <select
                    name="state1"
                    value={this.state.state1}
                    onChange={this.onChange}
                    className="select-css search-form-select form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                  >
                    {allstates.map((allstate, index) => (
                      <option key={index}>
                        {allstate.state.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" search-label-post col-lg-4 col-md-12 form-group">
                  {allstates.map((allstate, index) => {
                    if (allstate.state.toUpperCase() === this.state.state1) {
                      return (
                        <div key={index}>
                          {localStorage.setItem("stateId", index)}
                        </div>
                      );
                    } else {
                      return <div key={index}></div>;
                    }
                  })}

                  <select
                    name="search"
                    value={this.state.search}
                    onChange={this.onChange}
                    className="search-form-select select-css form-select form-select-lg mb-3"
                    aria-label=".form-select-sm example"
                  >
                    {allstates[localStorage.getItem("stateId")].districts.map(
                      (dist, index) => (
                        <option key={index}>{dist.toUpperCase()}</option>
                      )
                    )}
                  </select>
                </div>
                <div className=" col-lg-4 col-md-12 btn-cont form-group">
                  <button className="btn btn-primary search-btn btn-round">
                    Search
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
      if (this.state.loaded) {
        if (this.state.cityPosts.length === 0 || this.state.error === 1) {
          return (
            <div className="container">
              <div className="container search-container">
                <form onSubmit={this.onFormSubmit}>
                  <div className="row">
                    <div className="col-lg-4 col-md-12 form-group">
                      <select
                        name="state1"
                        value={this.state.state1}
                        onChange={this.onChange}
                        className="select-css search-form-select form-select form-select-lg mb-3"
                        aria-label=".form-select-sm example"
                      >
                        {allstates.map((allstate, index) => (
                          <option key={index}>
                            {allstate.state.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className=" col-lg-4 col-md-12 form-group">
                      {allstates.map((allstate, index) => {
                        if (
                          allstate.state.toUpperCase() === this.state.state1
                        ) {
                          return (
                            <div key={index}>
                              {localStorage.setItem("stateId", index)}
                            </div>
                          );
                        } else {
                          return <div key={index}></div>;
                        }
                      })}

                      <select
                        name="search"
                        value={this.state.search}
                        onChange={this.onChange}
                        className="select-css search-form-select form-select form-select-lg mb-3"
                        aria-label=".form-select-sm example"
                      >
                        {allstates[
                          localStorage.getItem("stateId")
                        ].districts.map((dist, index) => (
                          <option key={index}>{dist.toUpperCase()}</option>
                        ))}
                      </select>
                    </div>
                    <div className=" col-lg-4 col-md-12 btn-cont form-group">
                      <button className="btn btn-primary search-btn btn-round">
                        Search
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <h3 className="no-post">No Posts Yet!</h3>
              <div className=" av-post-inner post-opt-inner">
                <h3 className="btn-head-1">
Have any verified leads that might help someone out?                </h3>
                <br />

                <h4 className="btn-head-1">Then please do post.</h4>
                <Link to="/create-post-av">
                  <button className="btn post-opt-btn btn-info btn-round">
                   Post Here
                   <i className="fas fa-search"></i>
                  </button>
                </Link>
            </div>
            </div>
          );
        } else {
          return (
            <div className="container">
              <Notifications />

              <div className="container search-container">
                <form onSubmit={this.onFormSubmit}>
                  <div className="row">
                    <div className="col-lg-4 col-md-12 form-group">
                      <select
                        name="state1"
                        value={this.state.state1}
                        onChange={this.onChange}
                        className="select-css search-form-select form-select form-select-lg mb-3"
                        aria-label=".form-select-sm example"
                      >
                        {allstates.map((allstate, index) => (
                          <option key={index}>
                            {allstate.state.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className=" col-lg-4 col-md-12 form-group">
                      {allstates.map((allstate, index) => {
                        if (
                          allstate.state.toUpperCase() === this.state.state1
                        ) {
                          return (
                            <div key={index}>
                              {localStorage.setItem("stateId", index)}
                            </div>
                          );
                        } else {
                          return <div key={index}></div>;
                        }
                      })}
                      <select
                        name="search"
                        value={this.state.search}
                        onChange={this.onChange}
                        className="search-form-select select-css form-select form-select-lg mb-3"
                        aria-label=".form-select-sm example"
                      >
                        {allstates[
                          localStorage.getItem("stateId")
                        ].districts.map((dist, index) => (
                          <option key={index}>{dist.toUpperCase()}</option>
                        ))}
                      </select>
                    </div>
                    <div className=" col-lg-4 col-md-12 btn-cont form-group">
                      <button className="btn btn-primary search-btn btn-round">
                        Search
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="row row-1">
                <div className="col-lg-6">{x}</div>
                <div className="col-lg-6">{y}</div>
              </div>
              <div className="row row-2">
                <div className="col-md-12 col-sm-12">{z}</div>
              </div>
              <div className=" av-post-inner post-opt-inner">
                <h3 className="btn-head-1">
Have any verified leads that might help someone out?                </h3>
                <br />

                <h4 className="btn-head-1">Then please do post.</h4>
                <Link to="/create-post-av">
                  <button className="btn post-opt-btn btn-info btn-round">
                   Post Here
                   <i className="fas fa-search"></i>
                  </button>
                </Link>
             </div>
            </div>
          );
        }
      } else {
        if (!this.state.loaded) {
          return (
            <div className="loader">
              <Loader message="Loading" />
            </div>
          );
        }
      }
    }
  }
}

export default SearchBar;
