import React from "react";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Navbar.css";
class Navbar extends React.Component {
  state = {
    current: "Home",
  };

  onChange = () => {
    const str = window.location.pathname;
    const addr = str.substring(1);
    this.setState({ current: addr });
  };
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container">
            <a href="/" className="navbar-brand">
              Covid Network
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="navbar-toggler-icon "></span>
              <span className="navbar-toggler-icon "></span>
              <span className="navbar-toggler-icon "></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li
                  onClick={this.onChange}
                  className={
                    this.state.current === "home"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a href="/" className="nav-link">
                    Home
                  </a>
                </li>
                <li
                  onClick={this.onChange}
                  className={
                    this.state.current === "about"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a href="/about" className="nav-link">
                    About
                  </a>
                </li>
                <li
                  onClick={this.onChange}
                  className={
                    this.state.current === "signup"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a href="/CreatePost" className="nav-link">
                    Create Post
                    {/* <span className="sr-only">(current)</span> */}
                  </a>
                </li>
                <li
                  onClick={this.onChange}
                  className={
                    this.state.current === "signin"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <a href="/DeletePost" className="nav-link">
                    Delete Post
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
