import React from "react";
import axios from "axios";
import "./DeletePost.css";
import Notifications, { notify } from "react-notify-toast";

let customNotify = {
  background: "#000",
  text: "#fff",
};
class DeleteVerify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      success: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };
  onFormSubmit = (event) => {
    event.preventDefault();
    const id = localStorage.getItem("postId");
    axios({
      method: "DELETE",
      url:
        "https://covidnetwork-server.herokuapp.com/api/InNeedPost/deletePost",
      data: {
        id: id,
        email: this.state.email,
      },
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SECRET_TOKEN}`,
      },
    })
      .then((res) => {
        notify.show("Post Deleted Successfully", "custom", 2000, customNotify);
        this.setState({ success: 1 });
      })
      .catch((err) => {
        notify.show(
          "Oops! Something Went Wrong.Please check your E-mail Address.",
          "custom",
          4000,
          customNotify
        );
      });
  };
  render() {
    if (this.state.success === 1) {
      return (
        <div>
          <div className="container post-container">
            <div className="post-card">
              <div className="posted-card">
                <i className="checkmark">✓</i>
              </div>
              <div className="posted-msg">
                <h1>Success</h1>
                <h3>Your post is successfully deleted.</h3>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="verify">
          <Notifications />
          <div className="outer">
            <div className="inner">
              <form onSubmit={this.onFormSubmit}>
                <h4 className="verify-head">Verify Your E-mail Address</h4>
                <div className="form-group">
                  <input
                    required="required"
                    type="text"
                    name="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder="Enter E-mail Address"
                  />
                </div>

                <button
                  className="btn del-btn-ver btn-lg btn-dark btn-block"
                  type="submit"
                  name="verofy"
                >
                  {" "}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default DeleteVerify;
