import React from "react";
import "./About.css";

class About extends React.Component {
  render() {
    return (
      <div className="about-cont container">
        <div className="about-head">
          <span>About Us</span>
        </div>

        <div className="about-card-1 card">
          <div className="about-card-body-1 card-body">
            Keeping in view the ongoing pandemic that has taken a toll on almost
            every individual. With each passing day, we experienced an increase
            in Twitter posts and WhatsApp stories about the requirement of some
            form of medical aid which became really chaotic after a point of
            time because the individual needs to get their tweet/post shared to
            reach a larger audience and the actual supplier and this process
            gets even more tedious for the benefactor to search amongst the tons
            of irrelevant posts that they do not wish to see at the moment.
            Hence, to tackle this problem we decided to come up with a platform
            completely devoted to this cause where you can avail your
            requirement by searching , in case not available then by creating a
            post and posting it. Sounds similar to an Instagram story, Facebook
            post, or tweet? But it's more hassle-free than the social networking
            sites and you do not need to signup for performing any of this
            action. NGOs and other potential helpers can easily filter out the
            post by their locality and reach out to those in need.
            <div className="about-card-2 card">
              <div className="about-card-body-1 card-body">
                <span className="about-subhead"> Be a Helping Hand </span>{" "}
                <i className="fas fa-hand-holding-heart"></i>- <br /> <br />
                Do you possess things that can help someone in need? Be the
                voice someone is waiting to hear and add to the countless
                blessings that you must have been already abundantly showered
                upon.
              </div>
            </div>
            <div className="about-card-2 card">
              <div className="about-card-body-1 card-body">
                <span className="about-subhead"> How to use ?</span>
                <br />
                <br />
                1. If you require any medical aid you can{" "}
                <a className="about-href" href="/search-post-av">
                  Click Here
                </a>{" "}
                and search amongst the available resources posted by people in
                your city or make a fresh post consisting of your requirements.
                <br /> 2. If you want to be a Helping Hand, all you need to do
                is{" "}
                <a className="about-href" href="/search-post-inn">
                  Click Here
                </a>{" "}
                , search your city and read some posts posted by people in your
                city, and reach out to them to help.
              </div>
            </div>
            <div className="about-card-2 card">
              <div className="about-card-body-1 card-body">
                <span className="about-subhead"> NOTE </span> <br />
                <br />
                There’s no inherent motive other than helping people get their
                desired requirement and letting people who wish to deliver the
                same with a platform meant solely for the mentioned purpose. We
                do not need any of your data neither they are of any use to us.
                You always have the option to delete your post anytime, and in
                case you forgot, your post will get deleted automatically after
                seven days. <br />
                Besides the hard work being put into the making of the website,
                it's made with a lot of hope to help people put out their needs
                with ease and receive them without relying on other people to
                share their post. Its time that we stand up for each other for
                that you can provide the needful in case you cannot contribute
                directly by helping, help to reach this website to the required
                persons and places because
                <br />
                <p className="about-quote">
                  “We can’t help everyone but everyone can help someone”{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
