import React from "react";
import "./Footer.css";
class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-cont container">
          <span className="footer-quote">
            We are all in this together <span className="heart">❤</span> <br />{" "}
          </span>
          <br />
          <span className="footer-1">
            Have suggestions?              Contact
  <a href="mailto:covidnetworkassistance@gmail.com" className="footer-link" alt="mail-link">
              <i className="fas fa-envelope"></i>
            </a>


          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
// <a href="https://www.linkedin.com/in/hari-om-yadav-ab923a195/" className="footer-link" alt="mail-link">
//
// <i className="fab fa-linkedin"></i>            </a>
