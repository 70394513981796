import React from "react";
import "./CreatePost.css";
import { Link } from "react-router-dom";
class CreatePost extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <h1 className="ql-heading">Create Post</h1>
        <div className=" post-opt-outer">
          <div className=" post-opt-inner">
            <h2 className="btn-head"> In need of Something? </h2>

            <Link to="/create-post-inn">
              <button className="btn post-opt-btn btn-info btn-round">
                Post Here <i className="fas fa-arrow-right"></i>
              </button>
            </Link>
          </div>
        </div>

        <div className=" post-opt-outer">
          <div className=" post-opt-inner">
            <h2 className="btn-head"> Be a helping hand.</h2>

            <Link to="/create-post-av">
              <button className="btn post-opt-btn btn-info btn-round">
                Post Here <i className="fas fa-arrow-right"></i>
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePost;
