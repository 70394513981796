import React from "react";
import "./DeletePost.css";
import axios from "axios";
import Loader from "../Loader/Loader.js";
import Moment from "react-moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Notifications, { notify } from "react-notify-toast";
import allstates from "../CreatePost/state.js";

let customNotify = {
  background: "#000",
  text: "#fff",
};
class DeletePost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
state1:"",

      email: "",
      cityPosts1: [],
      cityPosts2: [],

      x: 0,
      y1: 0,
      id: 0,
      success: 0,
      error: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onDelChange = (event) => {
    localStorage.setItem("postId", event.target.value);
  };
  onChange = (event) => {
    this.setState({ loaded: false, x: 0, error: 0 });

    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };
  Onpop = (event) => {
    notify.show("Number Copied", "custom", 500, customNotify);
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ x: 1 });

    axios
      .get(
        `https://covidnetwork-server.herokuapp.com/api/InNeedPost/del/${this.state.search}/${this.state.email}`,
        {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SECRET_TOKEN}`,
          },
        }
      )
      .then((res) => {
        this.setState({ cityPosts1: res.data });
        if (res.data > 0) this.setState({ loaded: true });
        else {
          this.setState({ loaded: true });
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });

        this.setState({ error: 1 });
      });

    /////////////

    axios
      .get(
        `https://covidnetwork-server.herokuapp.com/api/AvailablePost/del/${this.state.search}/${this.state.email}`,
        {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SECRET_TOKEN}`,
          },
        }
      )
      .then((res) => {

        this.setState({ cityPosts2: res.data });
        if (res.data > 0) this.setState({ loaded: true });
        else {
          this.setState({ loaded: true });
        }
      })
      .catch((err) => {
        this.setState({ error: 1 });
      });
  };

  render() {
    const x = this.state.cityPosts1.map((cityPost, index) => {
      return (
        <div key={index} className="card-container container">
          <div className="card">
            <div className="card-header card-header-icon card-header-rose">
              <div className="card-icon">
                <i className="material-icons post-heading">
                  {cityPost.requirement} Needed
                </i>
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">
                <span className="sub-heading">Name:{"  "}</span>
                {cityPost.name},
                <span className="sub-heading">
                  {"  "}Age:{"  "}
                </span>
                {cityPost.age}
              </h4>
              <p className="card-text">
                <span className="sub-heading">City:{"  "}</span>
                {cityPost.city}
              </p>
              <p className="card-text">
                <span className="sub-heading">Mobile Number:{"  "}</span>
                {cityPost.mob1}
                <CopyToClipboard text={cityPost.mob1}>
                  <button
                    className="copy-btn"
                    id="copyButton"
                    name="copyId"
                    value={index}
                    onClick={() => this.Onpop(index)}
                  >
                    <i className="far fa-copy"></i>
                  </button>
                </CopyToClipboard>
              </p>
              <p className="card-text">
                <span className="sub-heading">Details:{"  "}</span>
                {cityPost.detail}
              </p>
              <p className="card-text">
                <span className="sub-heading">Posted On:{"  "}</span>
                <Moment format="DD/MM/YYYY">{cityPost.createdAt}</Moment>
              </p>
              <a href="/DeletePost-verify-inn">
                <button
                  type="button"
                  onClick={this.onDelChange}
                  value={cityPost._id}
                  className="btn btn-danger"
                >
                  Delete Post
                </button>
              </a>
              {/* <a href="/" className="btn btn-primary">Go somewhere</a> */}
            </div>
          </div>
        </div>
      );
    });

    const y = this.state.cityPosts2.map((cityPost, index) => {
      return (
        <div key={index} className="card-container container">
          <div className="card">
            <div className="card-header card-header-icon card-header-success">
              <div className="card-icon">
                <i className="material-icons post-heading">
                  {cityPost.resource} Available
                </i>
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">
                <span className="sub-heading">Name:{"  "}</span>
                {cityPost.name},
              </h4>
              <p className="card-text">
                <span className="sub-heading">City:{"  "}</span>
                {cityPost.city}
              </p>
              <p className="card-text">
                <span className="sub-heading">Location:{"  "}</span>
                {cityPost.address}, {cityPost.zipcode}
              </p>
              <p className="card-text">
                <span className="sub-heading">Mobile Number:{"  "}</span>
                {cityPost.mob1}
                <CopyToClipboard text={cityPost.mob1}>
                  <button
                    className="copy-btn"
                    id="copyButton"
                    name="copyId"
                    value={index}
                    onClick={() => this.Onpop(index)}
                  >
                    <i className="far fa-copy"></i>
                  </button>
                </CopyToClipboard>
              </p>
              <p className="card-text">
                <span className="sub-heading">Details:{"  "}</span>
                {cityPost.detail}
              </p>
              <p className="card-text">
                <span className="sub-heading">Posted On:{"  "}</span>
                <Moment format="DD/MM/YYYY">{cityPost.createdAt}</Moment>
              </p>
              <a href="/DeletePost-verify-av">
                <button
                  type="button"
                  onClick={this.onDelChange}
                  value={cityPost._id}
                  className="btn btn-danger"
                >
                  Delete Post
                </button>
              </a>
              {/* <a href="/" className="btn btn-primary">Go somewhere</a> */}
            </div>
          </div>
        </div>
      );
    });

    if (this.state.x === 0 && this.state.y1 === 0) {
      return (
        <div className="container del-container">
          <form onSubmit={this.onFormSubmit}>
            <div className="row">
                  <div className="col-lg-3 col-md-12 search-grp form-group">
                    <select
                      name="state1"
                      value={this.state.state1}
                      onChange={this.onChange}
                      className="select-css search-form-select form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                    >
                      {allstates.map((allstate, index) => (
                       <option key={index}>
                          {allstate.state.toUpperCase()}
                       </option>
                      ))}
                    </select>
                  </div>
                  <div className=" search-label-post col-lg-3 col-md-12 form-group">
                    {allstates.map((allstate, index) => {
                      if (
                       allstate.state.toUpperCase() === this.state.state1
                      ) {
                       return (
                          <div key={index}>
                            {localStorage.setItem("stateId", index)}
                          </div>
                       );
                      } else {
                       return <div key={index}></div>;
                      }
                    })}

                    <select
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      className="search-form-select select-css form-select form-select-lg mb-3"
                      aria-label=".form-select-sm example"
                    >
                      {allstates[
                       localStorage.getItem("stateId")
                      ].districts.map((dist, index) => (
                       <option key={index}>{dist.toUpperCase()}</option>
                      ))}
                    </select>
                  </div>

              <div className="form-group del-search col-lg-3 col-md-12">
                <input
                  type="text"
                  name="email"
                  className=" search-input"
                  value={this.state.email}
                  onChange={this.onChange}
                  placeholder="Enter Registered Email"
                />
              </div>

              <div className="col-lg-3 col-md-12 btn-cont form-group">
                <button className="btn btn-primary search-btn btn-round">
                  Search <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      if (this.state.loaded) {
        if (
          (this.state.cityPosts1.length === 0 &&
            this.state.cityPosts2.length === 0) ||
          this.state.error === 1
        ) {
          return (
            <div className="container">
              <div className="container del-container">
                    <form onSubmit={this.onFormSubmit}>
                      <div className="row">
                           <div className="col-lg-3 col-md-12 search-grp form-group">
                              <select
                                name="state1"
                                value={this.state.state1}
                                onChange={this.onChange}
                                className="select-css search-form-select form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                              >
                                {allstates.map((allstate, index) => (
                                 <option key={index}>
                                    {allstate.state.toUpperCase()}
                                 </option>
                                ))}
                              </select>
                           </div>
                           <div className=" search-label-post col-lg-3 col-md-12 form-group">
                              {allstates.map((allstate, index) => {
                                if (
                                 allstate.state.toUpperCase() === this.state.state1
                                ) {
                                 return (
                                    <div key={index}>
                                      {localStorage.setItem("stateId", index)}
                                    </div>
                                 );
                                } else {
                                 return <div key={index}></div>;
                                }
                              })}

                              <select
                                name="search"
                                value={this.state.search}
                                onChange={this.onChange}
                                className="search-form-select select-css form-select form-select-lg mb-3"
                                aria-label=".form-select-sm example"
                              >
                                {allstates[
                                 localStorage.getItem("stateId")
                                ].districts.map((dist, index) => (
                                 <option key={index}>{dist.toUpperCase()}</option>
                                ))}
                              </select>
                           </div>

                       <div className="form-group del-search col-lg-3 col-md-12">
                          <input
                           type="text"
                           name="email"
                           className=" search-input"
                           value={this.state.email}
                           onChange={this.onChange}
                           placeholder="Enter Registered Email"
                          />
                       </div>

                       <div className="col-lg-3 col-md-12 btn-cont form-group">
                          <button className="btn btn-primary search-btn btn-round">
                           Search <i className="fas fa-search"></i>
                          </button>
                       </div>
                      </div>
                    </form>
              </div>
              <h3 className="no-post"> No Posts Yet!</h3>
            </div>
          );
        } else if (this.state.cityPosts1.length === 0 || this.state.cityPosts2.length === 0 ) {
          return (
            <div>
              <Notifications />
              {this.state.cityPosts1.length === 0 ? (
                <div className="container">
                  <div className="container del-container">
                        <form onSubmit={this.onFormSubmit}>
                          <div className="row">
                                <div className="col-lg-3 col-md-12 search-grp form-group">
                                  <select
                                    name="state1"
                                    value={this.state.state1}
                                    onChange={this.onChange}
                                    className="select-css search-form-select form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                  >
                                    {allstates.map((allstate, index) => (
                                     <option key={index}>
                                        {allstate.state.toUpperCase()}
                                     </option>
                                    ))}
                                  </select>
                                </div>
                                <div className=" search-label-post col-lg-3 col-md-12 form-group">
                                  {allstates.map((allstate, index) => {
                                    if (
                                     allstate.state.toUpperCase() === this.state.state1
                                    ) {
                                     return (
                                        <div key={index}>
                                          {localStorage.setItem("stateId", index)}
                                        </div>
                                     );
                                    } else {
                                     return <div key={index}></div>;
                                    }
                                  })}

                                  <select
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.onChange}
                                    className="search-form-select select-css form-select form-select-lg mb-3"
                                    aria-label=".form-select-sm example"
                                  >
                                    {allstates[
                                     localStorage.getItem("stateId")
                                    ].districts.map((dist, index) => (
                                     <option key={index}>{dist.toUpperCase()}</option>
                                    ))}
                                  </select>
                                </div>

                            <div className="form-group del-search col-lg-3 col-md-12">
                              <input
                                type="text"
                                name="email"
                                className=" search-input"
                                value={this.state.email}
                                onChange={this.onChange}
                                placeholder="Enter Registered Email"
                              />
                            </div>

                            <div className="col-lg-3 col-md-12 btn-cont form-group">
                              <button className="btn btn-primary search-btn btn-round">
                                Search <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="del-head badge bg-secondary">
                        Available Resources
                      </h3>

                      {y}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="container del-container">
                        <form onSubmit={this.onFormSubmit}>
                          <div className="row">
                                <div className="col-lg-3 col-md-12 search-grp form-group">
                                  <select
                                    name="state1"
                                    value={this.state.state1}
                                    onChange={this.onChange}
                                    className="select-css search-form-select form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                  >
                                    {allstates.map((allstate, index) => (
                                     <option key={index}>
                                        {allstate.state.toUpperCase()}
                                     </option>
                                    ))}
                                  </select>
                                </div>
                                <div className=" search-label-post col-lg-3 col-md-12 form-group">
                                  {allstates.map((allstate, index) => {
                                    if (
                                     allstate.state.toUpperCase() === this.state.state1
                                    ) {
                                     return (
                                        <div key={index}>
                                          {localStorage.setItem("stateId", index)}
                                        </div>
                                     );
                                    } else {
                                     return <div key={index}></div>;
                                    }
                                  })}

                                  <select
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.onChange}
                                    className="search-form-select select-css form-select form-select-lg mb-3"
                                    aria-label=".form-select-sm example"
                                  >
                                    {allstates[
                                     localStorage.getItem("stateId")
                                    ].districts.map((dist, index) => (
                                     <option key={index}>{dist.toUpperCase()}</option>
                                    ))}
                                  </select>
                                </div>

                            <div className="form-group del-search col-lg-3 col-md-12">
                              <input
                                type="text"
                                name="email"
                                className=" search-input"
                                value={this.state.email}
                                onChange={this.onChange}
                                placeholder="Enter Registered Email"
                              />
                            </div>

                            <div className="col-lg-3 col-md-12 btn-cont form-group">
                              <button className="btn btn-primary search-btn btn-round">
                                Search <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="del-head badge bg-secondary">
                        In Need Resources
                      </h3>

                      {x}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div className="container">
              <Notifications />
              <div className="container del-container">
                    <form onSubmit={this.onFormSubmit}>
                      <div className="row">
                            <div className="col-lg-3 col-md-12 search-grp form-group">
                              <select
                                name="state1"
                                value={this.state.state1}
                                onChange={this.onChange}
                                className="select-css search-form-select form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                              >
                                {allstates.map((allstate, index) => (
                                 <option key={index}>
                                    {allstate.state.toUpperCase()}
                                 </option>
                                ))}
                              </select>
                            </div>
                            <div className=" search-label-post col-lg-3 col-md-12 form-group">
                              {allstates.map((allstate, index) => {
                                if (
                                 allstate.state.toUpperCase() === this.state.state1
                                ) {
                                 return (
                                    <div key={index}>
                                      {localStorage.setItem("stateId", index)}
                                    </div>
                                 );
                                } else {
                                 return <div key={index}></div>;
                                }
                              })}

                              <select
                                name="search"
                                value={this.state.search}
                                onChange={this.onChange}
                                className="search-form-select select-css form-select form-select-lg mb-3"
                                aria-label=".form-select-sm example"
                              >
                                {allstates[
                                 localStorage.getItem("stateId")
                                ].districts.map((dist, index) => (
                                 <option key={index}>{dist.toUpperCase()}</option>
                                ))}
                              </select>
                            </div>

                        <div className="form-group del-search col-lg-3 col-md-12">
                          <input
                            type="text"
                            name="email"
                            className=" search-input"
                            value={this.state.email}
                            onChange={this.onChange}
                            placeholder="Enter Registered Email"
                          />
                        </div>

                        <div className="col-lg-3 col-md-12 btn-cont form-group">
                          <button className="btn btn-primary search-btn btn-round">
                            Search <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h3 className="del-head badge bg-secondary">
                    In Need Resources
                  </h3>
                  {x}
                </div>
                <div className="col-lg-6">
                  <h3 className="del-head badge bg-secondary">
                    Available Resources
                  </h3>

                  {y}
                </div>
              </div>
            </div>
          );
        }
      } else {
        if (!this.state.loaded) {
          return (
            <div className="loader">
              <Loader message="Loading" />
            </div>
          );
        }
      }
    }
  }
}

export default DeletePost;
