import React from "react";
import "./CreatePost.css";
import axios from "axios";
import allstates from "./state.js";
import { Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

let customNotify = {
  background: "#000",
  text: "#fff",
};
const ress = [
  {
    in: 0,
    value: "Select Resource Type",
  },
  {
    in: 1,
    value: "Oxygen",
  },
  {
    in: 2,
    value: "Bed",
  },
  {
    in: 3,
    value: "Medicine",
  },
  {
    in: 4,
    value: "Blood",
  },
  {
    in: 5,
    value: "Plasma",
  },
  {
    in: 6,
    value: "Ambulance",
  },
  {
    in: 7,
    value: "Other",
  }
];
localStorage.setItem("stateId", 0);
// import {Redirect} from 'react-router-dom';
class Available extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      age: "",
      resource: 0,
      mob1: "",

      address: "",
      email: "",
      detail: "",
      state1: "",

      city: "",
      ind: 0,
      zipcode: "",
      date: "",
      success: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      resource,
      mob1,
      email,
      detail,
      address,
      state1,
      city,
    } = this.state;

    const user = {
      name,
      resource,
      mob1,
      email,
      detail,
      state1,
      address,
      city,
    };
    axios
      .post(
        "https://covidnetwork-server.herokuapp.com/api/AvailablePost/createPost",
        user,
        {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SECRET_TOKEN}`,
          },
        }
      )
      .then((res) => {
        this.setState({ success: 1 });
      })
      .catch((err) => {
        notify.show(
          "Oops! Something went wrong! Try Again.",
          "custom",
          2000,
          customNotify
        );
      });
  };

  render() {
    if (this.state.success === 1) {
      return <Redirect to="/posted" />;
    } else {
      return (
        <div className="createPost">
          <Notifications />
          <div className="outer">
            <div className="inner">
              <h3>Create Post</h3>
              <form onSubmit={this.onFormSubmit}>
                <div className="form-group">
                  <label className="label-post">
                    Enter Name<span className="asterik">*</span>{" "}
                  </label>

                  <input
                    required
                    type="text"
                    name="name"
                    className="form-control "
                    value={this.state.name}
                    onChange={this.onChange}
                    placeholder="Name"
                  />
                </div>

                <div className="form-group">
                  <label className="label-post">
                    Available Resource Type<span className="asterik">*</span>{" "}
                  </label>

                  <select
                    name="resource"
                    value={this.state.resource}
                    onChange={this.onChange}
                    className="form-select form-select-sm mb-3"
                    aria-label=".form-select-sm example"
                  >
                    {ress.map((res, index) => (
                      <option key={index}>{res.value.toUpperCase()}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="label-post">
                    State<span className="asterik">*</span>{" "}
                  </label>
                  <select
                    name="state1"
                    value={this.state.state1}
                    onChange={this.onChange}
                    className="form-select form-select-sm mb-3"
                    aria-label=".form-select-sm example"
                  >
                    {allstates.map((allstate, index) => (
                      <option key={index}>
                        {allstate.state.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  {allstates.map((allstate, index) => {
                    if (allstate.state.toUpperCase() === this.state.state1) {
                      return (
                        <div key={index}>
                          {localStorage.setItem("stateId", index)}
                        </div>
                      );
                    } else {
                      return <div key={index}></div>;
                    }
                  })}
                  <label className="label-post">
                    District<span className="asterik">*</span>
                  </label>

                  <select
                    name="city"
                    value={this.state.city}
                    onChange={this.onChange}
                    className="form-select form-select-sm mb-3"
                    aria-label=".form-select-sm example"
                  >
                    {allstates[localStorage.getItem("stateId")].districts.map(
                      (dist, index) => (
                        <option key={index}>{dist.toUpperCase()}</option>
                      )
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <label className="label-post">
                    Enter Address<span className="asterik">*</span>{" "}
                  </label>

                  <input
                    type="text"
                    name="address"
                    className="form-control "
                    value={this.state.address}
                    onChange={this.onChange}
                    placeholder="Address"
                  />
                </div>
                <div className="form-group">
                  <label className="label-post">
                    Enter Contact Number<span className="asterik">*</span>{" "}
                  </label>

                  <input
                    required
                    type="tel"
                    name="mob1"
                    className="form-control "
                    value={this.state.mob1}
                    onChange={this.onChange}
                    placeholder="Contact Number"
                  />
                </div>

                <div className="form-group">
                  <label className="label-post">Enter E-mail<span className="asterik">*</span> <span className="email-disc">(E-mail will not be displayed on your post)
                </span> </label>

                  <input
                    required
                    type="email"
                    name="email"
                    className="form-control "
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder="E-mail "
                  />
                </div>

                <div className="form-group">
                  <label className="label-post">Enter Details<span className="asterik">*</span> </label>

                  <textarea
                    required
                    name="detail"
                    className="form-control "
                    value={this.state.detail}
                    onChange={this.onChange}
                    placeholder="Enter Detail about the available resource "
                  />
                </div>
                <label className="crt-post-alert btn-head">
                  <span className="asterik">*</span>Kindly remember the above
                  entered email address as it will be required for
                  authentication if you wish to delete your post.
                </label>

                <button
                  className="btn btn-lg btn-dark post-btn btn-block"
                  type="submit"
                  name="signup"
                >
                  Post
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Available;
// <div className="form-group">
//  <label className="label-post">
//     Enter Alt Contact Number{" "}
//  </label>
//
//  <input
//     type="tel"
//     name="mob2"
//     className="form-control "
//     value={this.state.mob2}
//     onChange={this.onChange}
//     placeholder="Alternate Contact Number"
//  />
// </div>
//
//  <div className="form-group">
//  <label className="label-post">
//     Enter ZIP Code<span className="asterik">*</span>{" "}
//  </label>
//
//  <input
//     required
//     type="text"
//     name="zipcode"
//     className="form-control "
//     value={this.state.zipcode}
//     onChange={this.onChange}
//     placeholder="ZIP Code"
//  />
// </div>
//
