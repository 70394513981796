import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar.js";
import Footer from "./Components/Footer/Footer.js";
import Home from "./Components/Home/Home.js";
import Error from "./Components/Home/Error.js";

import About from "./Components/About/About.js";
import CreatePost from "./Components/CreatePost/CreatePost.js";

import InNeed from "./Components/CreatePost/InNeed.js";
import Available from "./Components/CreatePost/Available.js";

import Posted from "./Components/CreatePost/Posted.js";
import SearchInNeed from "./Components/SearchBar/NeedPost.js";
import AvailablePost from "./Components/SearchBar/AvailablePost.js";

import DeletePost from "./Components/DeletePost/DeletePost.js";
import DeleteVerifyInn from "./Components/DeletePost/DeleteVerifyInn.js";
import DeleteVerifyAv from "./Components/DeletePost/DeleteVerifyAv.js";

class App extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div className="App">
        <Router>
<div>
      <Navbar/>

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/CreatePost" component={CreatePost} />

            <Route exact path="/search-post-inn" component={SearchInNeed} />

            <Route exact path="/search-post-av" component={AvailablePost} />

            <Route exact path="/create-post-inn" component={InNeed} />
            <Route exact path="/create-post-av" component={Available} />

            <Route exact path="/Posted" component={Posted} />
            <Route exact path="/DeletePost" component={DeletePost} />
            <Route
              exact
              path="/DeletePost-verify-inn"
              component={DeleteVerifyInn}
            />
            <Route
              exact
              path="/DeletePost-verify-av"
              component={DeleteVerifyAv}
            />
            <Route path="*" component={Error}/>
          </Switch>
</div>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
