import React from "react";
import { Link } from "react-router-dom";
class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      extra: false,
    };
  }

  onExtra = () => {
    this.setState({ extra: true });
  };
  render() {
    return (
      <div className="container-fluid">

        <div className=" post-opt-outer">
          <div className=" post-opt-inner">
            <h2 className="btn-head">Search/Post your Requirement </h2>

            <Link to="/search-post-av">
              <button className="btn post-opt-btn btn-info btn-round">
                Post Here <i className="fas fa-search"></i>
              </button>
            </Link>
          </div>
        </div>

        <div className=" post-opt-outer">
          <div className=" post-opt-inner">
            <h2 className="btn-head">Be a Helping Hand</h2>

            <Link to="/search-post-inn">
              <button className="btn post-opt-btn btn-info btn-round">
                Head Here <i className="fas fa-hand-holding-heart"></i>
              </button>
            </Link>
          </div>
        </div>
        <hr className="home-hr" />

        <div className="container search-container">
          <div className="card disc-card card-nav-tabs">
                <h1 className="disc-card-header-info card-header card-header-info">
               Covid Network- A single platform for all Covid related
              posts.
            </h1>
            <div className="card-body">
              <p className="card-text disc-text">
                Our country has been severely hit by the pandemic and there’s
                turmoil all around. Amidst this, the need of the hour is to
                stand for each other by helping the ones in need. To fulfil the
                same, you can take the help of this website if you are running
                an NGO or are a potential helper by searching for people in need
                in your vicinity and contact them. Most importantly, you can
                seek help by creating a post if you are the one who is in need
                of any assistance. The posts will be anyways moderated but
                kindly refrain from creating deceptive posts so that the help
                can reach the genuine individual.
              </p>

              <a href="/about" className="btn read-more btn-primary">
                To Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
