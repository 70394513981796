import React from "react";
import "./CreatePost.css";
class Posted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      age: "",
      requirement: "",
      mob1: "",
      mob2: "",
      email: "",
      detail: "",
      states: "",

      city: "",
      ind: 0,
      zipcode: "",
      date: "",
      success: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container post-container">
        <div className="post-card">
          <div className="posted-card">
            <i className="checkmark">✓</i>
          </div>
          <div className="posted-msg">
            <h1>Success</h1>
            <h3>Your post is successfully created.</h3>
            <h4>Do consider deleting your post once your purpose is served.</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Posted;
