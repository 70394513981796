import React from "react";
import { RingLoader } from "react-spinners";
import "./Loader.css";

const AwesomeComponent = (props) => {
  const style = {
    position: "fixed",
    top: "30%",
    left: "45%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div>
      <div className="circle-loader" style={style}>
        <RingLoader size={90} color={"#440a67"} />
      </div>
    </div>
  );
};

export default AwesomeComponent;
// <p className="loading-text">{props.message} </p>
