import React from "react";

import SearchBar from "../SearchBar/SearchBar.js";

import './Home.css';
class Home extends React.Component {
  render() {
    return (
      <div>
<h2 className="home-head"> </h2>
<SearchBar/>
      </div>






    );
  }
}

export default Home;
