
import React from "react";
class Error extends React.Component {
  render() {
    return (
      <div>
      <div className="post-card">
        <div className="posted-card-1">
        <i className="exclamation">!</i>
    </div>
        <div className="posted-msg">
         <h1>Error 404</h1>
         <h3>Page Not Found</h3>
        </div>
      </div>

      </div>

    );
  }
}

export default Error;
